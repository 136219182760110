import { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FaDatabase, FaExclamationTriangle, FaRegCopy } from "react-icons/fa";
import { IoIosDownload, IoIosRefresh } from "react-icons/io";
import axios from "axios";

import {
  Keypair,
  PublicKey,
  LAMPORTS_PER_SOL,
  Connection,
} from "@solana/web3.js";
import {
  getMint,
  getAccount,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import BigNumber from "bignumber.js";
import bs58 from "bs58";
import { AppContext } from "../App";
import { ellipsisAddress, isValidAddress } from "../utils/methods";
import SolRandAmountDialog from "../components/Dialogs/SolRandAmountDialog";
import TokenRandAmountDialog from "../components/Dialogs/TokenRandAmountDialog";
import { sleep } from "../utils/solana";

const connection = new Connection(process.env.REACT_APP_RPC_URL);
export default function ManualBuyPage({ className }) {
  const {
    SERVER_URL,
    setLoadingPrompt,
    setOpenLoading,
    user,
    setCurBuyTokenAddress,
    curBuyTokenAddress,
    setWalletList,
    walletList,
    buyDelayTime,
    setBuyDelayTime,
    manualSlippage,
    setManualSlippage,
    manualJitoTip,
    setManualJitoTip,
  } = useContext(AppContext);

  const [copied, setCopied] = useState({});
  const [walletAllChecked, setWalletAllChecked] = useState(false);
  const [walletChecked, setWalletChecked] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [solAmountDialog, setSolAmountDialog] = useState(false);
  const [tokenAmountDialog, setTokenAmountDialog] = useState(false);
  const [isCheckedTokenAddress, setCheckedTokenAddress] = useState(false);

  useEffect(() => {
    const checkList = [];
    setWalletAllChecked(false);
    walletList.map(() => {
      checkList.push(false);
    });

    setWalletChecked(checkList);
  }, []);

  useEffect(() => {
    setLoadingPrompt("Update wallets...");
    setOpenLoading(true);
    updateBalance();
    setOpenLoading(false);
  }, [curBuyTokenAddress]);

  const updateBalance = async () => {
    if (curBuyTokenAddress === "") {
      return;
    }

    console.log("updateBalance....");
	let mint = '';
	try{
		mint = new PublicKey(curBuyTokenAddress);
	} catch (err) {
		console.log('Invalid token address:', curBuyTokenAddress);
		toast.warn("Invalid token address!");
        return;
	}
    
    const checkList = [];
    const mintInfo = await getMint(connection, mint);
    const walletDataList = await Promise.all(
      walletList.map(async (wallet) => {
        const walletAddress = wallet.walletAddress;
        const balance =
          (await connection.getBalance(
            new PublicKey(walletAddress),
            "confirmed"
          )) / LAMPORTS_PER_SOL;
        const buySolAmount = 0;
        let tokenAmount = 0;

        if (curBuyTokenAddress !== "") {
          try {
            const owner = new PublicKey(walletAddress);
            const tokenATA = await getAssociatedTokenAddress(mint, owner);
            const tokenAccountInfo = await getAccount(connection, tokenATA);
            tokenAmount = Number(
              new BigNumber(
                tokenAccountInfo.amount.toString() +
                  "e-" +
                  mintInfo.decimals.toString()
              ).toString()
            ).toFixed(mintInfo.decimals);
            console.log("tokenAmount:", tokenAmount);
          } catch (error) {
            // notification('Failed to fetch token balance.', 'warning');
            console.log("error:", error);
            tokenAmount = 0;
          }
        }
        const sellTokenAmount = 0;
        checkList.push(false);
        return {
          walletAddress: walletAddress,
          balance,
          buySolAmount,
          walletP: wallet.walletP,
          tokenAmount,
          sellTokenAmount,
        };
      })
    );
    console.log("updateBalance:end");
    setWalletList(walletDataList);
  };

  const handleRefresh = async () => {
    setLoadingPrompt("Update wallets...");
    setOpenLoading(true);
    await updateBalance();
    setOpenLoading(false);
  };

  const copyToClipboard = async (key, text) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
      toast.success("Copied");
      setCopied({
        ...copied,
        [key]: true,
      });
      setTimeout(
        () =>
          setCopied({
            ...copied,
            [key]: false,
          }),
        2000
      );
    } else console.error("Clipboard not supported");
  };

  const handleImportWallets = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/plain") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        setLoadingPrompt("Import wallets...");
        setOpenLoading(true);
        const content = e.target.result;
        const wallets = content.split("\n").map((wallet) => wallet.trim());
        const checkList = [];
        let mint;
        let mintInfo;
        if (curBuyTokenAddress !== "") {
          mint = new PublicKey(curBuyTokenAddress);
          mintInfo = await getMint(connection, mint);
        }

        const walletDataList = await Promise.all(
          wallets.map(async (wallet) => {
            const walletAddress = Keypair.fromSecretKey(
              bs58.decode(wallet)
            ).publicKey;
            const balance =
              (await connection.getBalance(walletAddress, "confirmed")) /
              LAMPORTS_PER_SOL;
            const buySolAmount = 0;
            const sellTokenAmount = 0;
            let tokenAmount = 0;
            if (curBuyTokenAddress !== "") {
              try {
                const owner = new PublicKey(walletAddress);
                const tokenATA = await getAssociatedTokenAddress(mint, owner);
                const tokenAccountInfo = await getAccount(connection, tokenATA);
                tokenAmount = Number(
                  new BigNumber(
                    tokenAccountInfo.amount.toString() +
                      "e-" +
                      mintInfo.decimals.toString()
                  ).toString()
                ).toFixed(mintInfo.decimals);
              } catch (error) {
                // notification('Failed to fetch token balance.', 'warning');
                tokenAmount = 0;
              }
            }
            checkList.push(false);
            return {
              walletAddress: walletAddress,
              balance,
              buySolAmount,
              walletP: wallet,
              tokenAmount,
              sellTokenAmount,
            };
          })
        );
        setWalletChecked(checkList);
        setWalletAllChecked(false);
        setWalletList(walletDataList);
        setOpenLoading(false);
      };

      reader.onerror = () => {
        alert("Error reading the file!");
      };

      reader.readAsText(file);
    } else {
      alert("Please select a valid .txt file.");
    }
  };

  const handleMouseDown = (e, id) => {
    e.preventDefault();
    setIsDragging(true);
    handleWalletChanged(id, "checked", !walletChecked[id]);
  };

  const handleMouseEnter = (id) => {
    if (isDragging) {
      handleWalletChanged(id, "checked", !walletChecked[id]);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleWalletAllChecked = (e) => {
    const newWalletAllChecked = !walletAllChecked;
    setWalletAllChecked(newWalletAllChecked);
    setWalletChecked(walletChecked.map(() => newWalletAllChecked));
  };

  const handleWalletChanged = (index, key, value) => {
    if (key === "checked") {
      let newWalletChecked = [...walletChecked];
      newWalletChecked[index] = !newWalletChecked[index];
      setWalletChecked(newWalletChecked);

      let newWalletAllChecked = true;
      for (let i = 0; i < newWalletChecked.length; i++)
        newWalletAllChecked &&= newWalletChecked[i];
      setWalletAllChecked(newWalletAllChecked);
    }
  };

  const handleOKMinMaxSolAmounts = (minAmount, maxAmount) => {
    function getRandomNumber(min, max) {
      return Math.random() * (max - min) + min;
    }

    const walletDataList = walletList.map((wallet, item_index) => {
      const solAmount = getRandomNumber(
        parseFloat(minAmount),
        parseFloat(maxAmount)
      );
      return walletChecked[item_index] === true
        ? {
            ...wallet,
            buySolAmount:
              solAmount > wallet.balance - 0.01
                ? wallet.balance - 0.01
                : parseFloat(solAmount).toFixed(3),
          }
        : wallet;
    });
    setWalletList(walletDataList);

    setSolAmountDialog(false);
  };

  const handleOKMinMaxTokenAmounts = (minAmount, maxAmount) => {
    function getRandomNumber(min, max) {
      return Math.random() * (max - min) + min;
    }

    const walletDataList = walletList.map((wallet, item_index) => {
      const tokenAmount = getRandomNumber(
        parseFloat(minAmount),
        parseFloat(maxAmount)
      ).toFixed(2);
      return walletChecked[item_index] === true
        ? { ...wallet, sellTokenAmount: tokenAmount }
        : wallet;
    });
    setWalletList(walletDataList);

    setTokenAmountDialog(false);
  };

  const handleSolAmountChanged = (index, value) => {
    const walletDataList = walletList.map((wallet, item_index) =>
      item_index === index ? { ...wallet, buySolAmount: value } : wallet
    );
    setWalletList(walletDataList);
  };

  const handleTokenAmountChanged = (index, value) => {
    const walletDataList = walletList.map((wallet, item_index) =>
      item_index === index ? { ...wallet, sellTokenAmount: value } : wallet
    );
    setWalletList(walletDataList);
  };

  const handleSetSolAmounts = () => {
    const selectedWallets = walletChecked.filter((item) => item === true);
    if (selectedWallets.length === 0) {
      toast.warn("Please select wallets to set token amount");
      return;
    }
    setSolAmountDialog(true);
  };

  const handleSetTokenAmounts = () => {
    const selectedWallets = walletChecked.filter((item) => item === true);
    if (selectedWallets.length === 0) {
      toast.warn("Please select wallets to set token amount");
      return;
    }
    setTokenAmountDialog(true);
  };

  const handleManualBuyTokens = async () => {
    if (curBuyTokenAddress === "") {
      toast.warn("Input token address!");
      return;
    }

    const isChecked = walletChecked.includes(true) ? true : false;
    if (isChecked === false) {
      toast.warn("Select the wallets!");
      return;
    }

    if (isCheckedTokenAddress === false) {
      setLoadingPrompt("Checking this token is avaliable...");
      setOpenLoading(true);
      try {
        const response = await axios.post(
          `${SERVER_URL}/api/v1/project/check_pumpfun`,
          {
            targetTokenAddress: curBuyTokenAddress,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "MW-USER-ID": localStorage.getItem("access-token"),
            },
          }
        );

        if (response.data.ok === false) {
          toast.warn("Invalid token address!");
          setOpenLoading(false);
          return;
        }
      } catch (err) {
        toast.warn("Invalid token address!");
        setOpenLoading(false);
        return;
      }
      setOpenLoading(false);
      setCheckedTokenAddress(true);
    }
    const buyWalletList = [];
    for (let i = 0; i < walletList.length; i++) {
      if (walletChecked[i] === false) continue;
      if (walletList[i].balance < walletList[i].buySolAmount + 0.0001) {
        toast.success("Check the sol balance! #" + i);
        return;
      }
      buyWalletList.push(walletList[i]);
    }

    setLoadingPrompt("Buy tokens...");
    setOpenLoading(true);
    console.log("'Buy tokens...");

    try {
      const { data } = await axios.post(
        `${SERVER_URL}/api/v1/project/buy-manual-tokens`,
        {
          userId: user._id,
          tokenAddress: curBuyTokenAddress,
          walletsData: buyWalletList,
          walletList,
          manualJitoTip,
          delayTime: buyDelayTime,
          slippage: manualSlippage,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "MW-USER-ID": localStorage.getItem("access-token"),
          },
        }
      );

      if (data.success === true) {
        toast.success("Buy token successfully");
        const mint = new PublicKey(curBuyTokenAddress);
        const mintInfo = await getMint(connection, mint);
        const walletDataList = await Promise.all(
          walletList.map(async (wallet, index) => {
            const walletAddress = new PublicKey(wallet.walletAddress);
            const balance =
              (await connection.getBalance(walletAddress, "confirmed")) /
              LAMPORTS_PER_SOL;
            let tokenAmount = 0;
            console.log("curBuyTokenAddress:", curBuyTokenAddress);
            if (curBuyTokenAddress !== "") {
              try {
                const owner = new PublicKey(walletAddress);
                const tokenATA = await getAssociatedTokenAddress(mint, owner);
                const tokenAccountInfo = await getAccount(connection, tokenATA);
                tokenAmount = Number(
                  new BigNumber(
                    tokenAccountInfo.amount.toString() +
                      "e-" +
                      mintInfo.decimals.toString()
                  ).toString()
                ).toFixed(mintInfo.decimals);
              } catch (error) {
                // notification('Failed to fetch token balance.', 'warning');
                tokenAmount = 0;
              }
            }
            const sellTokenAmount = 0;
            return {
              walletAddress: wallet.walletAddress,
              balance,
              buySolAmount: wallet.buySolAmount,
              walletP: wallet.walletP,
              tokenAmount,
              sellTokenAmount,
            };
          })
        );
        setWalletList(walletDataList);
      } else {
        toast.warn("Buy token failed");
      }
    } catch (err) {
      toast.success("Buy token failed");
      console.log(err);
    }
    setLoadingPrompt("Update wallets...");
    await sleep(10000);
    await updateBalance();
    setOpenLoading(false);
    console.log("Buy end*****************");
    // setTimeout(() => {
    // 	updateBalance();
    // }, 10000);
    // setOpenLoading(false);
  };

  const handleManualSellTokens = async () => {
    if (curBuyTokenAddress === "") {
      toast.warn("Input token address!");
      return;
    }

    const isChecked = walletChecked.includes(true) ? true : false;
    if (isChecked === false) {
      toast.warn("Select the wallets!");
      return;
    }

    if (isCheckedTokenAddress === false) {
      setLoadingPrompt("Checking this token is avaliable...");
      setOpenLoading(true);
      try {
        const response = await axios.post(
          `${SERVER_URL}/api/v1/project/check_pumpfun`,
          {
            targetTokenAddress: curBuyTokenAddress,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "MW-USER-ID": localStorage.getItem("access-token"),
            },
          }
        );

        if (response.data.ok === false) {
          toast.warn("Invalid token address!");
          setOpenLoading(false);
          return;
        }
      } catch (err) {
        toast.warn("Invalid token address!");
        setOpenLoading(false);
        return;
      }
      setOpenLoading(false);
      setCheckedTokenAddress(true);
    }
    const sellWalletList = [];
    for (let i = 0; i < walletList.length; i++) {
      if (walletChecked[i] === false) continue;
      if (walletList[i].balance < 0.0005) {
        toast.success("Check the sol balance! #" + i);
        return;
      }
      if (
        walletList[i].tokenAmount * (walletList[i].sellTokenAmount / 100) <
        1
      ) {
        toast.success("Check the token balance! #" + i);
        return;
      }
      sellWalletList.push(walletList[i]);
    }

    setLoadingPrompt("Sell tokens...");
    setOpenLoading(true);

    try {
      const { data } = await axios.post(
        `${SERVER_URL}/api/v1/project/sell-manual-tokens`,
        {
          userId: user._id,
          tokenAddress: curBuyTokenAddress,
          walletsData: sellWalletList,
          manualJitoTip,
          delayTime: buyDelayTime,
          slippage: manualSlippage,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "MW-USER-ID": localStorage.getItem("access-token"),
          },
        }
      );

      if (data.tokenType === "Pumpfun-Migration") {
        setOpenLoading(false);
        toast.warn("Token is migrated now.");
        return;
      }

      if (data.success === true) {
        toast.success("Sell tokens successfully");

        console.log("Sell tokens successfully");
        const walletDataList = await Promise.all(
          walletList.map(async (wallet, index) => {
            const balance =
              (await connection.getBalance(
                new PublicKey(wallet.walletAddress),
                "confirmed"
              )) / LAMPORTS_PER_SOL;
            let tokenAmount = 0;
            if (curBuyTokenAddress !== "") {
              try {
                const parsedTokenAccounts =
                  await connection.getParsedTokenAccountsByOwner(
                    new PublicKey(wallet.walletAddress),
                    {
                      mint: new PublicKey(curBuyTokenAddress),
                    }
                  );
                console.log("Sell-----parsedTokenAccounts");
                // Check if there are any accounts associated with this wallet and token
                if (parsedTokenAccounts.value.length > 0) {
                  tokenAmount =
                    parsedTokenAccounts.value[0].account.data.parsed.info
                      .tokenAmount.uiAmountString;
                  console.log("Sell-----length>0");
                }
              } catch (error) {
                // notification('Failed to fetch token balance.', 'warning');
                console.log("Sell-----error", error);
                tokenAmount = 0;
              }
            }
            const sellTokenAmount = 0;
            return {
              walletAddress: wallet.walletAddress,
              balance,
              buySolAmount: wallet.buySolAmount,
              walletP: wallet.walletP,
              tokenAmount,
              sellTokenAmount,
            };
          })
        );
        setWalletList(walletDataList);
      } else {
        toast.warn("Sell tokens failed");
      }
    } catch (err) {
      toast.success("Buy token failed");
      console.log(err);
    }
    setLoadingPrompt("Update wallets...");
    await sleep(10000);
    await updateBalance();
    setOpenLoading(false);
    console.log("Sell end*****************");
    // setTimeout(() => {
    // 	updateBalance();
    // }, 10000);
  };

  return (
    <div
      className={`${className} flex flex-col text-white rounded-[4px] border border-gray-highlight p-4 pb-3`}
    >
      <SolRandAmountDialog
        isOpen={solAmountDialog}
        onOK={handleOKMinMaxSolAmounts}
        onCancel={() => setSolAmountDialog(false)}
      />
      <TokenRandAmountDialog
        isOpen={tokenAmountDialog}
        onOK={handleOKMinMaxTokenAmounts}
        onCancel={() => setTokenAmountDialog(false)}
      />
      <div className="flex flex-col">
        <div className="flex items-start gap-3 sm:gap-5 flex-col sm:flex-row w-full h-auto">
          <div className="flex gap-3 flex-col">
            <div className="flex items-center font-sans text-xs font-medium text-white">
              <div className="font-bold uppercase min-w-[150px]">
                Token Address:{" "}
              </div>
              <div className="flex items-center grow">
                <input
                  className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button ml-2 grow max-w-[430px]"
                  placeholder="Target Token Address"
                  value={curBuyTokenAddress}
                  onChange={(e) => {
                    setCheckedTokenAddress(false);
                    setCurBuyTokenAddress(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex items-center font-sans text-xs font-medium text-white">
              <div className="font-bold uppercase  min-w-[150px]">
                Jito-tip Amount:{" "}
              </div>
              <div className="flex items-center grow">
                <input
                  className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button ml-2 grow max-w-[430px]"
                  placeholder="Jito-tip"
                  value={manualJitoTip}
                  onChange={(e) => {
                    setManualJitoTip(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-3 flex-col">
            <div className="flex items-center font-sans text-xs font-medium text-white">
              <div className="font-bold uppercase  min-w-[110px]">
                Delay Time (s):{" "}
              </div>
              <div className="flex items-center grow">
                <input
                  className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button ml-2 grow max-w-[430px]"
                  placeholder="Jito-tip"
                  value={buyDelayTime}
                  onChange={(e) => {
                    setBuyDelayTime(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex items-center font-sans text-xs font-medium text-white">
              <div className="font-bold uppercase  min-w-[110px]">
                Slippage (%):{" "}
              </div>
              <div className="flex items-center grow">
                <input
                  className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button ml-2 grow max-w-[430px]"
                  placeholder="Jito-tip"
                  value={manualSlippage}
                  onChange={(e) => {
                    setManualSlippage(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between w-full gap-2 mt-3 mb-3 font-sans">
          <div className="flex items-center gap-3 font-sans text-sm text-gray-normal">
            <div>
              Selected:{" "}
              <span className="text-white">
                {walletChecked.filter((wal) => wal).length}
              </span>
            </div>
            <button
              className="pl-3 pr-4 h-button rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
              onClick={handleRefresh}
            >
              <IoIosRefresh className="text-lg text-red-normal" />
              Refresh
            </button>
          </div>
          <div className="flex flex-col justify-end gap-2 lg:items-center lg:flex-row">
            <button
              className="pl-3 pr-4 h-button rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
              onClick={handleSetTokenAmounts}
            >
              <FaDatabase className="text-sm text-red-normal" />
              Set token amount (sell)
            </button>
            <button
              className="pl-3 pr-4 h-button rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
              onClick={handleSetSolAmounts}
            >
              <img
                className="w-4 h-4 text-red-normal"
                src="/assets/sol.svg"
                alt="sol"
              />
              Set Sol Amount (buy)
            </button>
            <button
              className="pl-3 pr-4 h-button rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
              onClick={() => document.getElementById("fileInput").click()}
            >
              <IoIosDownload className="text-lg text-red-normal" />
              Import Wallets
            </button>
            <input
              id="fileInput"
              type="file"
              accept=".txt"
              style={{ display: "none" }}
              onChange={handleImportWallets}
            />
          </div>
        </div>
        <div className="w-full overflow-visible font-sans">
          <div className="flex flex-col w-full h-full text-white bg-transparent bg-clip-border">
            <div className="relative border border-gray-highlight">
              <div
                className={`${"h-[calc(100vh-370px)] 2xl:h-[calc(100vh-340px)]"} overflow-y-auto`}
              >
                {(!walletList || walletList.length === 0) && (
                  <div className="absolute flex items-center justify-center gap-2 my-3 text-base font-bold text-center uppercase -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-gray-border">
                    <FaExclamationTriangle className="text-sm opacity-50 text-red-normal" />{" "}
                    No Wallet
                  </div>
                )}
                <table className="min-w-[700px] w-full text-xs">
                  <thead className="text-gray-normal">
                    <tr className="uppercase h-7 bg-[#262626] sticky top-0 z-10">
                      <th className="w-8 text-center">
                        <div className="flex items-center justify-center">
                          <input
                            type="checkbox"
                            className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                            checked={walletAllChecked}
                            onChange={handleWalletAllChecked}
                          />
                        </div>
                      </th>
                      <th className="w-8">#</th>
                      <th className="">Wallet Address</th>
                      <th className="text-left">SOL Balance</th>
                      <th className="text-left">Token Balance</th>
                      <th className="text-left">Sol Amount (buy)</th>
                      <th className="text-left">Token Amount(sell %)</th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-xs text-white"
                    onMouseLeave={handleMouseUp}
                  >
                    {walletList &&
                      walletList.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className={`${
                              index % 2 === 1 && "bg-[#ffffff02]"
                            } hover:bg-[#ffffff08] ${
                              walletChecked[index] && "!bg-[#00000030]"
                            } h-8`}
                          >
                            <td
                              className="text-center"
                              onMouseDown={(e) => handleMouseDown(e, index)}
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseUp={handleMouseUp}
                            >
                              <div className="flex items-center justify-center">
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                                  checked={walletChecked[index]}
                                />
                              </div>
                            </td>
                            <td className="text-center">
                              <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                                {index + 1}
                              </p>
                            </td>
                            <td className="text-center">
                              <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-gray-normal">
                                <p className="bg-transparent border-none outline-none">
                                  {ellipsisAddress(item.walletAddress, true)}
                                  {/* {item.address} */}
                                </p>
                                {copied["wallet_" + index] ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-3 h-3"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M5 13l4 4L19 7"
                                    />
                                  </svg>
                                ) : (
                                  <FaRegCopy
                                    className="w-3 h-3 transition ease-in-out transform cursor-pointer active:scale-95 duration-90"
                                    onClick={() =>
                                      copyToClipboard(
                                        "wallet_" + index,
                                        item.walletAddress
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </td>
                            <td className="">
                              <p className="flex items-center justify-start text-yellow-normal">
                                <img
                                  className="w-3 mr-1"
                                  src="/assets/solsemi.svg"
                                  alt="sol"
                                />
                                {item.balance}
                              </p>
                            </td>
                            <td className="">
                              <p className="flex items-center justify-start text-white">
                                <FaDatabase className="mr-1 opacity-50 text-xxs text-gray-normal" />
                                <span>
                                  {item.tokenAmount
                                    ? Number(
                                        item.tokenAmount
                                          .toString()
                                          ?.split(".")[0] ?? "0"
                                      ).toLocaleString()
                                    : "0"}
                                </span>
                                <span className="font-normal text-gray-normal">
                                  .
                                  {item.tokenAmount
                                    ? item.tokenAmount.toString()?.split(".")[1]
                                    : "0000"}
                                </span>
                              </p>
                            </td>
                            <td className="text-center">
                              <input
                                className="outline-none border border-gray-highlight font-medium text-gray-normal placeholder:text-gray-border text-xs px-2.5 bg-transparent text-center w-[100px] h-[26px]"
                                value={item.buySolAmount}
                                onChange={(e) =>
                                  handleSolAmountChanged(index, e.target.value)
                                }
                              />
                            </td>
                            <td className="text-center">
                              <input
                                className="outline-none border border-gray-highlight font-medium text-gray-normal placeholder:text-gray-border text-xs px-2.5 bg-transparent text-center w-[100px] h-[26px]"
                                value={item.sellTokenAmount}
                                onChange={(e) =>
                                  handleTokenAmountChanged(
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col md:flex-row items-center justify-center h-full gap-3 mt-3 text-white bg-transparent bg-clip-border">
          <div className="flex justify-center gap-20 w-full">
            <button
              className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-red-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
              onClick={handleManualBuyTokens}
            >
              Buy
            </button>
            <button
              className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-red-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
              onClick={handleManualSellTokens}
            >
              Sell
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
