import { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdDashboard, MdOutlineHistory, MdOutlineSell } from 'react-icons/md';
import { RiProjectorFill, RiExchangeDollarLine } from 'react-icons/ri';
import {
	FaTools,
	FaRobot,
	FaChartBar,
	FaUsers,
	FaRegCopyright,
	FaTelegram,
	FaTwitter,
	FaBook,
	FaExchangeAlt,
} from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { BiSolidPurchaseTag, BiTransferAlt } from 'react-icons/bi';
import { GrDeploy } from 'react-icons/gr';
import { PiSwimmingPool } from 'react-icons/pi';
import { MdOutlineToken } from 'react-icons/md';

import { AppContext } from '../App';

export default function SideBarComponent({ className }) {
	const { user, currentProject } = useContext(AppContext);
	const navigate = useNavigate();
	const location = useLocation();
	const [openProjectMenu, setOpenProjectMenu] = useState(false);
	const [openBotsMenu, setOpenBotsMenu] = useState(false);

	useEffect(() => {
		if (
			location.pathname === '/pumpcreate' ||
			location.pathname === '/pumpbuy' ||
			location.pathname === '/pumpsell' ||
			location.pathname === '/pumptransfer'||
			location.pathname === '/manualbuy'
		) {
			setOpenProjectMenu(true);
			setOpenBotsMenu(false);
		}
	}, [location.pathname]);

	const handleCollapse = (e, menuName) => {
		e.stopPropagation();
		if (menuName === 'pumpfun') {
			const newopenProjectMenu = !openProjectMenu;
			setOpenProjectMenu(newopenProjectMenu);
			if (newopenProjectMenu) {
				setOpenBotsMenu(false);
			}
		} else if (menuName === 'bots') {
			const newOpenBotsMenu = !openBotsMenu;
			setOpenBotsMenu(newOpenBotsMenu);
		}
	};

	const handlePumpCreate = () => {
		if (currentProject._id) navigate('/pumpcreate');
	};

	const handlePumpBuy = () => {
		if (currentProject._id) navigate('/pumpbuy');
	};

	const handlePumpSell = () => {
		if (currentProject._id) navigate('/pumpsell');
	};

	const handlePumpTransfer = () => {
		if (currentProject._id) navigate('/pumptransfer');
	};
	const handleManualBuy = () => {
		if (currentProject._id) {
			navigate('/manualbuy');
		}
	};

	return (
		<div
			className={`${className} font-sans flex-col gap-2 items-center text-gray-normal relative`}>
			{/* <img
				src={`/logo.png`}
				className='hidden 2xl:block w-full max-w-[147px]  mt-5 m-auto cursor-pointer'
				alt=''
				onClick={() => navigate('/')}
			/> */}
			<div className='absolute bottom-0 w-full p-5'>
				{/* {
					<img
						className='flex mb-5 item-center disabled'
						src='/assets/coin.png'
						alt='coin'
					/>
				} */}
				<div className='items-center justify-center gap-4 2xl:flex xl:grid'>
					<a href='https://t.me/powerful115' target='__blank'>
						<FaTelegram className='w-5 h-5 text-white' />
					</a>
					<FaTwitter className='w-5 h-5 text-gray-800' />
					{/* <a href='https://sniperpad.gitbook.io/memepump' target='__blank'>
						<FaBook className='w-5 h-5 text-white' />
					</a> */}
				</div>
			</div>

			<div
				className={`w-[50px] 2xl:w-full h-9 uppercase hover:bg-[rgba(255,255,255,0.1)] flex justify-center text-sm 2xl:justify-start mx-auto 2xl:px-5 gap-4 items-center mt-5 cursor-pointer ${
					location.pathname === '/dashboard'
						? 'bg-gray-highlight text-white font-medium'
						: ''
				} `}
				onClick={() => navigate('/dashboard')}>
				<MdDashboard className='w-[18px] h-[18px] relative' />
				<div className='hidden text-sm 2xl:block'>Dashboard</div>
			</div>
			{/* pump fun start */}
			<div
				className={`w-[50px] 2xl:w-full h-9 uppercase hover:bg-[rgba(255,255,255,0.1)] flex justify-center text-sm 2xl:justify-start mx-auto 2xl:px-5 gap-4 items-center mt-[1px] cursor-pointer ${
					location.pathname === '/pumpcreate' ||
					location.pathname === '/pumpbuy' ||
					location.pathname === '/pumpsell' ||
					location.pathname === '/pumptransfer' ||
					location.pathname === '/manualbuy' ||
					location.pathname === '/metric'
						? 'bg-[rgba(255,255,255,0.1)]'
						: ''
				}`}
				onClick={(e) => handleCollapse(e, 'pumpfun')}>
				<RiProjectorFill className='w-[18px] h-[18px] relative' />
				<div className='items-center justify-between hidden w-[calc(100%-34px)] 2xl:flex'>
					<div className='w-full text-left'>PumpFun</div>
					<IoIosArrowDown
						className={`w-4 h-full ${
							openProjectMenu ? 'transform rotate-180' : ''
						}`}
					/>
				</div>
			</div>
			{openProjectMenu && (
				<div className=''>
					<div
						className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] ${'cursor-pointer'} ${
							location.pathname === '/pumpcreate'
								? 'bg-gray-highlight text-white font-medium'
								: ''
						}`}
						onClick={handlePumpCreate}>
						<BiSolidPurchaseTag className='w-[18px] h-[18px]' />
						<div className='hidden text-sm 2xl:flex'>Create</div>
					</div>
					{currentProject._id ? (
						<div
							className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px]  ${'cursor-pointer'} ${
								location.pathname === '/pumpbuy'
									? 'bg-gray-highlight text-white font-medium'
									: ''
							}  `}
							onClick={handlePumpBuy}>
							<MdOutlineSell className='w-[18px] h-[18px]' />
							<div className='hidden text-sm 2xl:flex'>Buy</div>
						</div>
					) : (
						<div
							className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px]  ${'cursor-pointer'} ${
								location.pathname === '/pumpbuy'
									? 'bg-gray-highlight text-white font-medium'
									: ''
							}  `}>
							<MdOutlineSell className='w-[18px] h-[18px]' />
							<div className='hidden text-sm 2xl:flex'>Buy</div>
						</div>
					)}
					{currentProject._id ? (
						<div
							className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px]  ${'cursor-pointer'} ${
								location.pathname === '/pumpsell'
									? 'bg-gray-highlight text-white font-medium'
									: ''
							}  `}
							onClick={handlePumpSell}>
							<MdOutlineSell className='w-[18px] h-[18px]' />
							<div className='hidden text-sm 2xl:flex'>Sell</div>
						</div>
					) : (
						<div
							className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px]  ${'cursor-pointer'} ${
								location.pathname === '/pumpsell'
									? 'bg-gray-highlight text-white font-medium'
									: ''
							}  `}>
							<MdOutlineSell className='w-[18px] h-[18px]' />
							<div className='hidden text-sm 2xl:flex'>Sell</div>
						</div>
					)}
					{currentProject._id ? (
						<div
							className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] ${'cursor-pointer'} ${
								location.pathname === '/pumptransfer'
									? 'bg-gray-highlight text-white font-medium'
									: ''
							} `}
							onClick={handlePumpTransfer}>
							<BiTransferAlt className='w-[18px] h-[18px]' />
							<div className='hidden text-sm 2xl:flex'>Transfer</div>
						</div>
					) : (
						<div
							className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] ${'cursor-pointer'} ${
								location.pathname === '/pumptransfer'
									? 'bg-gray-highlight text-white font-medium'
									: ''
							} `}>
							<BiTransferAlt className='w-[18px] h-[18px]' />
							<div className='hidden text-sm 2xl:flex'>Transfer</div>
						</div>
					)}
					{currentProject._id ? (
						<div
							className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] ${'cursor-pointer'} ${
								location.pathname === '/manualbuy'
									? 'bg-gray-highlight text-white font-medium'
									: ''
							} `}
							onClick={handleManualBuy}>
							<MdOutlineSell className='w-[18px] h-[18px]' />
							<div className='hidden text-sm 2xl:flex'>Custom Buy</div>
						</div>
					) : (
						<div
							className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] ${'cursor-pointer'} ${
								location.pathname === '/manualbuy'
									? 'bg-gray-highlight text-white font-medium'
									: ''
							} `}>
							<MdOutlineSell className='w-[18px] h-[18px]' />
							<div className='hidden text-sm 2xl:flex'>Custom Buy</div>
						</div>
					)}
				</div>
			)}
			{/* pump fun end */}

			<div
				className={`w-[50px] 2xl:w-full h-9 uppercase hover:bg-[rgba(255,255,255,0.1)] flex justify-center text-sm 2xl:justify-start mx-auto 2xl:px-5 gap-4 items-center mt-[1px] cursor-pointer`}
				onClick={(e) => handleCollapse(e, 'bots')}>
				<FaRobot className='w-[18px] h-[18px] relative' />
				<div className='items-center justify-between hidden w-[calc(100%-34px)] 2xl:flex'>
					<div className='w-full text-left'>Bots</div>
					<IoIosArrowDown
						className={`w-4 h-full ${
							openBotsMenu ? 'transform rotate-180' : ''
						}`}
					/>
				</div>
			</div>
			{openBotsMenu && (
				<div className=''>
					<div
						className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] cursor-pointer tracking-tighter`}
						onClick={() =>
							window.open('https://t.me/trustai_pumpfun_volume_bot', '_blank')
						}>
						<FaChartBar />
						<div className='hidden text-sm 2xl:flex'>Volume Bot</div>
					</div>
				</div>
			)}
			{/* {openBotsMenu && (
				<div className=''>
					<div
						className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] cursor-pointer`}
						onClick={() =>
							window.open('https://t.me/MPHolderMakerSolana_bot', '_blank')
						}>
						<FaUsers className='w-[18px] h-[18px]' />
						<div className='hidden text-sm 2xl:flex'>Holder Bot</div>
					</div>
				</div>
			)}
			{openBotsMenu && (
				<div className=''>
					<div
						className={`w-[50px] 2xl:w-full h-9 hover:bg-[rgba(255,255,255,0.1)] flex justify-center 2xl:justify-start mx-auto 2xl:pl-7 gap-4 items-center mt-[1px] cursor-pointer tracking-tighter`}
						onClick={() =>
							window.open('https://t.me/MPBuySellSolana_bot', '_blank')
						}>
						<FaExchangeAlt className='w-[18px] h-[18px]' />
						<div className='hidden text-sm 2xl:flex'>Buy Sell Bot</div>
					</div>
				</div>
			)} */}
			{user.role === 'admin' && (
				<div
					className={`w-[50px] 2xl:w-full h-9 uppercase hover:bg-[rgba(255,255,255,0.1)] flex justify-center text-sm 2xl:justify-start mx-auto 2xl:px-5 gap-4 items-center mt-[1px] cursor-pointer ${
						location.pathname === '/log'
							? 'bg-gray-highlight text-white font-medium'
							: ''
					} `}
					onClick={() => navigate('/log')}>
					<MdOutlineHistory className='w-[18px] h-[18px] relative' />
					<div className='hidden text-sm 2xl:block'>Log</div>
				</div>
			)}
		</div>
	);
}
